import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'
import { CardError } from 'components'
import {
  Modal,
  MultiSelectField,
  SelectField,
  Button,
  ButtonBlock,
} from '@politechdev/blocks-design-system'
import { useRequest } from 'hooks'
import { fetchLocations } from 'requests/locations'
import { buildMultiOptions, buildSingleOptions } from 'utils/select'
import { useCurrent } from 'contexts/index'
import LocationForm from 'locations/LocationForm/LocationForm'
import { LOCATION_TYPES } from 'constants/locations'
import { createPortal } from 'react-dom'
import {
  buildParams,
  buildLocation,
  buildOption,
  type Location,
  type LocationOption,
} from './utils'

type CommonProps = {
  label: string
  locationTypes: Array<{ key: keyof typeof LOCATION_TYPES }>
  filters: unknown[]
  allowCreateNew?: boolean
}

type SingleProps = CommonProps & {
  isMulti?: false
  location?: Location
  locations?: never
  onSelect: (location: Location | undefined) => void
}

type MultiProps = CommonProps & {
  location?: never
  locations?: Location[]
  isMulti: true
  onSelect: (locations: Location[]) => void
}

const LocationSelectField = ({
  label,
  locationTypes,
  filters,
  location,
  locations = [],
  allowCreateNew = false,
  isMulti,
  onSelect,
  ...props
}: SingleProps | MultiProps) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState<LocationOption[]>([])
  const [errorMsg, setErrorMsg] = useState<string>()
  const [value, setValue] = useState(location?.id)
  const [multiValue, setMultiValue] = useState(locations.map(({ id }) => id))
  const [query, setQuery] = useState<string | undefined>('')
  const [debounced] = useDebounce(query, 300)

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const openModal = () => setCreateModalOpen(true)
  const closeModal = () => setCreateModalOpen(false)

  const { qcEnabled, doesCurrentUserHavePermission } = useCurrent() as {
    qcEnabled: boolean
    doesCurrentUserHavePermission: (permission: unknown) => boolean
  }
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'location',
    ability: 'view',
  })

  const { makeRequest, isLoading } = useRequest<{ locations: Location[] }>(
    fetchLocations,
    {
      onSuccess: ({ locations: incomingLocations }) => {
        setOptions(
          incomingLocations.map(incomingLocation =>
            buildOption(qcEnabled, incomingLocation)
          )
        )
      },
      onError: () => {
        setErrorMsg('failed to fetch locations')
      },
    }
  )

  const getLocations = () => {
    const params = buildParams({
      query: debounced ?? '',
      locationTypes,
      filters,
    })

    if (hasPermission) {
      void makeRequest(params)
    }
  }

  useEffect(() => {
    getLocations()
  }, [debounced])

  useEffect(() => {
    setValue(location?.id)
  }, [location?.id])

  useEffect(() => {
    setMultiValue(locations.map(({ id }) => id))
  }, [JSON.stringify(locations)])

  const singleOptions = useMemo(
    () =>
      buildSingleOptions(options, location, loc => buildOption(qcEnabled, loc)),
    [options, location?.id, qcEnabled]
  )

  const multiOptions = useMemo(
    () =>
      buildMultiOptions(options, locations, loc => buildOption(qcEnabled, loc)),
    [options, location?.id, qcEnabled]
  )

  const sharedProps = {
    label: label || t('Location'),
    onInputChange: setQuery,
    loading: isLoading,
    disabled: !hasPermission,
    hint: !hasPermission
      ? t('You do not have permission to edit this field')
      : undefined,
    ...props,
  }

  return (
    <>
      <CardError hide={!errorMsg} message={errorMsg} />
      {isMulti ? (
        <MultiSelectField<number>
          icon={undefined}
          values={multiValue}
          options={multiOptions}
          onSelect={ids => {
            setMultiValue(ids)
            onSelect(
              multiOptions.filter(o => ids.includes(o.value)).map(buildLocation)
            )
          }}
          onClear={() => setQuery('')}
          {...sharedProps}
        >
          {allowCreateNew && (
            <SelectField.Action onClick={openModal}>
              Create New
            </SelectField.Action>
          )}
        </MultiSelectField>
      ) : (
        <SelectField<number | undefined>
          value={value}
          options={singleOptions}
          onSelect={id => {
            setValue(id)
            onSelect(singleOptions.find(o => o.value === id)?.location)
          }}
          onClear={() => {
            setQuery('')
            setValue(undefined)
            onSelect(undefined)
          }}
          {...sharedProps}
        >
          {allowCreateNew && (
            <SelectField.Action onClick={openModal}>
              Create New
            </SelectField.Action>
          )}
        </SelectField>
      )}
      {createPortal(
        <Modal title="Create Location" isOpen={createModalOpen}>
          <LocationForm
            location={undefined}
            locationType={
              locationTypes.length === 1 ? locationTypes[0].key : undefined
            }
            onSuccess={({ location: newLocation }: { location: Location }) => {
              const newOption = buildOption(qcEnabled, newLocation)
              let newOptions: LocationOption[]
              setOptions(opts => {
                newOptions = [...opts, newOption]
                return newOptions
              })
              if (isMulti) {
                setMultiValue(values => {
                  const newValues = [...values, newOption.value]
                  onSelect(
                    newOptions
                      .filter(({ value: v }) => newValues.includes(v))
                      .map(buildLocation)
                  )
                  return newValues
                })
              } else {
                setValue(newOption.value)
                onSelect(newLocation)
              }
              closeModal()
            }}
            renderFields={fields => <Modal.Body>{fields}</Modal.Body>}
            renderActions={submitButton => (
              <Modal.Actions>
                <ButtonBlock>
                  {submitButton}
                  <Button.Secondary onClick={closeModal}>
                    {t('Cancel')}
                  </Button.Secondary>
                </ButtonBlock>
              </Modal.Actions>
            )}
          />
        </Modal>,
        document.body,
        'create-location-modal'
      )}
    </>
  )
}

export default LocationSelectField
