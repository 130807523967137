import { LOCATION_TYPES } from 'constants/locations'

export type Location = {
  id: number
  name: string
  street_address: unknown
  turf?: {
    name: string
  }
}

export type LocationOption = {
  value: Location['id']
  label: string
  location: Location
}

export const buildOption = (qcEnabled: boolean, location: Location) => {
  if (qcEnabled && location.turf?.name) {
    return {
      value: location.id,
      label: `${[location.name, location.street_address].join(' - ')} (${
        location.turf.name
      })`,
      location,
    }
  }

  return {
    value: location.id,
    label: [location.name, location.street_address].join(' - '),
    location,
  }
}

export const buildLocation = ({ location }: LocationOption) => location

export const buildParams = ({
  query,
  locationTypes,
  filters = [],
}: {
  query: string
  locationTypes?: Array<{ key: keyof typeof LOCATION_TYPES }>
  filters: unknown[]
}) => ({
  filters: {
    rules: [
      query && query !== ''
        ? {
            rules: [
              {
                column: 'name',
                operator: 'containing',
                param: query,
              },
              {
                column: 'street_address',
                operator: 'containing',
                param: query,
              },
            ],
            conjunction: 'inclusive',
          }
        : undefined,
      locationTypes && locationTypes.length > 0
        ? {
            rules: locationTypes.map(locationType => ({
              column: 'location_type',
              operator: 'is',
              param: locationType.key,
            })),
            conjunction: 'inclusive',
          }
        : undefined,
      ...filters,
    ],
    conjunction: 'exclusive',
  },
  sort_attr: 'name',
  sort_dir: 'asc',
  per: 25,
  indexed: true,
  fields: ['id', 'name', 'street_address', { turf: 'name' }],
})
