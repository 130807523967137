import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNumber } from 'lodash'
import {
  FieldBlock,
  Button,
  ButtonBlock,
  TextBlock,
  TextField,
  SelectField,
  ContentBlock,
} from '@politechdev/blocks-design-system'
import {
  ZipCodeField,
  CardError,
  LoadBar,
  CountySelectField,
  ToggleBoxGroup,
  TurfSelectField,
} from 'components'
import { useCurrent, useForm, useTurfs } from 'contexts'
import { useReactRouter, useRequest } from 'hooks'
import {
  LOCATION_TYPES,
  PETITION_LOCATION_TYPES,
  REGISTRATION_LOCATION_TYPES,
} from 'constants/locations'
import {
  acceptablePrecisionValues,
  fetchAddressOptions,
} from 'components/AddressValidator/smartyStreetsApiHelpers'
import { postLocation, putLocation } from 'requests/locations'
import { SHIFT_TYPE } from 'shifts/constants'
import { buildForm } from './utils'

const LocationForm = ({
  formId = 'location-form',
  location,
  locationType,
  onSuccess,
  renderFields = fields => fields,
  renderActions = submitButton => <ButtonBlock>{submitButton}</ButtonBlock>,
}) => {
  const isEditForm = !!location
  const formAction = isEditForm
    ? formData => putLocation(location.id, formData)
    : postLocation
  const { t } = useTranslation()
  const { history, match } = useReactRouter()
  const {
    currentUser: {
      turf: { id: turfId },
    },
    currentTenantStateOptions,
    currentTurfLocationTurfable: locationTurfable,
    locationCategories,
  } = useCurrent()

  const { currentTurfs: turfOptions, refreshCurrentTurfs } = useTurfs()

  const [missingAddress, setMissingAddress] = useState(false)
  const [duplicateAddress, setDuplicateAddress] = useState(false)

  const { formData, setField, setFormData } = useForm()

  useEffect(() => {
    if (locationType) {
      setField(LOCATION_TYPES[locationType].value, 'location_type')
    }
  }, [locationType])

  const { shiftType } = match.params
  const locationTypeOptions =
    shiftType === SHIFT_TYPE.PETITION
      ? PETITION_LOCATION_TYPES
      : REGISTRATION_LOCATION_TYPES

  const { makeRequest, isLoading, hasErrors, isRequestComplete, response } =
    useRequest(formAction, {
      onError: e => {
        if (e.json.error.delivery_point_barcode) {
          setDuplicateAddress(true)
        }
      },
    })

  const {
    makeRequest: addressCheck,
    isLoading: addressCheckIsLoading,
    hasErrors: addressCheckHasErrors,
  } = useRequest(fetchAddressOptions, {
    onSuccess: ({ candidates }) => {
      if (
        candidates.length > 0 &&
        candidates[0].metadata.latitude &&
        candidates[0].metadata.longitude &&
        candidates[0].delivery_point_barcode &&
        acceptablePrecisionValues.includes(candidates[0].metadata.precision)
      ) {
        makeRequest({
          ...formData,
          latitude: candidates[0].metadata.latitude,
          longitude: candidates[0].metadata.longitude,
          turf_id: formData.turf_id || turfId,
          delivery_point_barcode: candidates[0].delivery_point_barcode,
        })
      } else {
        setMissingAddress(true)
      }
    },
  })

  useEffect(() => {
    locationTurfable && refreshCurrentTurfs()
    location && setFormData(buildForm(location))
  }, [location, locationTurfable])

  const isSubformValid = () => {
    switch (formData.location_type) {
      case LOCATION_TYPES.canvassing.value: {
        return locationCategories.length ? !isEmpty(formData.category) : true
      }
      default:
        return true
    }
  }

  const isFormValid = () =>
    !isEmpty(formData.name) &&
    !isEmpty(formData.street_address) &&
    !isEmpty(formData.city) &&
    !isEmpty(formData.state) &&
    !isEmpty(formData.zipcode) &&
    !isEmpty(formData.county) &&
    isNumber(formData.location_type) &&
    (!locationTurfable || formData.turf_id) &&
    isSubformValid()

  useEffect(() => {
    if (hasErrors || !isRequestComplete) return

    onSuccess
      ? onSuccess(response)
      : history.push(`/collect/${match.params.shiftType}/locations`)
  }, [isRequestComplete, hasErrors, response])

  const submitForm = e => {
    e.preventDefault()
    setMissingAddress(false)
    setDuplicateAddress(false)
    addressCheck({
      fields: {
        street: formData.street_address,
        city: formData.city,
        state: formData.state,
        zipcode: formData.zipcode,
      },
      match: 'invalid',
    })
  }

  return (
    <>
      <LoadBar show={isLoading || addressCheckIsLoading} />
      <CardError
        hide={
          !hasErrors &&
          !missingAddress &&
          !duplicateAddress &&
          !addressCheckHasErrors
        }
        hideSupportLink
        message={
          (duplicateAddress && 'Duplicate address') ||
          ((hasErrors || addressCheckHasErrors) && 'Error creating location') ||
          (missingAddress && 'Could not verify address')
        }
      />
      <form id={formId}>
        {renderFields(
          <>
            <FieldBlock>
              <TextField
                id="name"
                label={t('Name')}
                value={formData.name}
                onChange={val => setField(val, 'name')}
                required
              />
            </FieldBlock>
            {!locationType && (
              <ContentBlock>
                <ToggleBoxGroup
                  startingIndex={
                    isEditForm
                      ? locationTypeOptions.findIndex(
                          ({ key }) => key === location.location_type
                        )
                      : undefined
                  }
                  options={locationTypeOptions}
                  onChange={option =>
                    option
                      ? setField(option.value, 'location_type')
                      : setField(undefined, 'location_type')
                  }
                  dataLabel="icon"
                  dataDetail="label"
                />
              </ContentBlock>
            )}
            {formData.location_type === LOCATION_TYPES.canvassing.value ? (
              <CanvassingFields
                formData={formData}
                setField={setField}
                currentTenantStateOptions={currentTenantStateOptions}
                turfOptions={turfOptions}
                locationTurfable={locationTurfable}
                isEditForm={isEditForm}
              />
            ) : null}
            {formData.location_type === LOCATION_TYPES.delivery.value ? (
              <DeliveryFields
                formData={formData}
                setField={setField}
                currentTenantStateOptions={currentTenantStateOptions}
                turfOptions={turfOptions}
                locationTurfable={locationTurfable}
              />
            ) : null}
          </>
        )}
        {renderActions(
          <Button.Accent
            form={formId}
            disabled={!isFormValid() || isLoading}
            onClick={submitForm}
          >
            {t(isEditForm ? 'Save Location' : 'Create Location')}
          </Button.Accent>
        )}
      </form>
    </>
  )
}

export default LocationForm

const CanvassingFields = ({
  formData,
  setField,
  currentTenantStateOptions,
  locationTurfable,
  isEditForm,
}) => {
  const { t } = useTranslation()
  const { locationCategories } = useCurrent()

  return (
    <>
      {locationCategories.length ? (
        <FieldBlock>
          <SelectField
            label={t('Category')}
            onSelect={val => setField(val, 'category')}
            emptyMessage="No options"
            value={formData.category}
            options={locationCategories.map(category => ({
              value: category,
              label: category,
            }))}
            required
          />
        </FieldBlock>
      ) : null}
      <FieldBlock>
        <TextField
          id="street_address"
          label={t('Street Address')}
          value={formData.street_address}
          onChange={val => setField(val, 'street_address')}
          required
        />
      </FieldBlock>
      <FieldBlock>
        <TextField
          id="city"
          label={t('City')}
          value={formData.city}
          onChange={val => setField(val, 'city')}
          required
        />
        <SelectField
          id="state"
          label={t('State')}
          options={currentTenantStateOptions}
          value={formData.state}
          onSelect={val => {
            if (val !== formData.state) {
              setField('', 'county')
            }
            setField(val, 'state')
          }}
          required
        />
      </FieldBlock>
      <FieldBlock>
        <ZipCodeField
          id="zipcode"
          label={t('Zip Code')}
          value={formData.zipcode}
          onChange={val => setField(val, 'zipcode')}
          required
        />
        <CountySelectField
          state={formData.state}
          county={formData.county}
          onSelect={val => setField(val, 'county')}
          required
        />
      </FieldBlock>
      {locationTurfable && (
        <FieldBlock>
          <TurfSelectField
            id="turf_id"
            label={t('Turf')}
            value={formData.turf_id}
            onSelect={val => setField(val, 'turf_id')}
            showArchived={isEditForm}
            disableArchived
            required
          />
        </FieldBlock>
      )}
      <TextBlock>
        <TextField
          id="notes"
          label={t('Notes')}
          value={formData.notes}
          onChange={val => setField(val, 'notes')}
        />
      </TextBlock>
    </>
  )
}

const DeliveryFields = ({
  formData,
  setField,
  currentTenantStateOptions,
  locationTurfable,
  isEditForm,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <FieldBlock>
        <TextField
          id="street_address"
          label={t('Street Address')}
          value={formData.street_address}
          onChange={val => setField(val, 'street_address')}
          required
        />
      </FieldBlock>
      <FieldBlock>
        <TextField
          id="city"
          label={t('City')}
          value={formData.city}
          onChange={val => setField(val, 'city')}
          required
        />
        <SelectField
          id="state"
          label={t('State')}
          options={currentTenantStateOptions}
          value={formData.state}
          onSelect={val => {
            if (val !== formData.state) {
              setField('', 'county')
            }
            setField(val, 'state')
          }}
          required
        />
      </FieldBlock>
      <FieldBlock>
        <ZipCodeField
          id="zipcode"
          label={t('Zip Code')}
          value={formData.zipcode}
          onChange={val => setField(val, 'zipcode')}
          required
        />
        <CountySelectField
          state={formData.state}
          county={formData.county}
          onSelect={val => setField(val, 'county')}
          required
        />
      </FieldBlock>
      {locationTurfable && (
        <FieldBlock>
          <TurfSelectField
            id="turf_id"
            label={t('Turf')}
            value={formData.turf_id}
            onSelect={val => setField(val, 'turf_id')}
            showArchived={isEditForm}
            disabledArchived
            required
          />
        </FieldBlock>
      )}
      {isEditForm && (
        <>
          <FieldBlock>
            <TextField
              id="hours"
              label={t('Hours')}
              value={formData.hours}
              onChange={val => setField(val, 'hours')}
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="primary_point_of_contact"
              label={t('Primary Point of Contact')}
              value={formData.primary_point_of_contact}
              onChange={val => setField(val, 'primary_point_of_contact')}
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="phone_number"
              type="tel"
              label={t('Phone Number')}
              value={formData.phone_number}
              onChange={val => setField(val, 'phone_number')}
            />
          </FieldBlock>
          <FieldBlock>
            <TextField
              id="email"
              label={t('Email')}
              type="email"
              value={formData.email}
              onChange={val => setField(val, 'email')}
            />
          </FieldBlock>
        </>
      )}
      <TextBlock>
        <TextField
          id="notes"
          label={t('Notes')}
          value={formData.notes}
          onChange={val => setField(val, 'notes')}
        />
      </TextBlock>
    </>
  )
}
