import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchLocations = params => {
  const queryString = stringifyParams(params, { strictNullHandling: true })

  return fetchJSON(`/api/v1/locations?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchLocationMap = params => {
  const queryString = stringifyParams(params, { strictNullHandling: true })

  return fetchJSON(`/api/v1/locations/map?${queryString}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchLocation = (id, params = {}) =>
  fetchJSON(`/api/v1/locations/${id}?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postLocation = location =>
  fetchJSON(
    '/api/v1/locations',
    'POST',
    { location, fields: ['id', 'name', 'street_address', { turf: 'name' }] },
    { useJwt: true }
  )

export const putLocation = (id, location) =>
  fetchJSON(
    `/api/v1/locations/${id}`,
    'PUT',
    { location },
    {
      useJwt: true,
    }
  )

export const destroyLocation = id =>
  fetchJSON(`/api/v1/locations/${id}`, 'DELETE', null, {
    useJwt: true,
  })
