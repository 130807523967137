import { Icon } from '@politechdev/blocks-design-system'

export const LOCATION_TYPES = {
  canvassing: {
    key: 'canvassing',
    value: 0,
    label: 'Canvassing',
    i18NextKey: 'location-type-canvassing',
    icon: Icon.ClipboardList({}),
  },
  delivery: {
    key: 'delivery',
    value: 11,
    label: 'Delivery',
    i18NextKey: 'Delivery',
    icon: Icon.Truck({}),
  },
  venue: {
    key: 'venue',
    value: 12,
    label: 'Venue',
    i18NextKey: 'Venue',
  },
} as const

export const ALL_LOCATION_TYPES = [
  LOCATION_TYPES.canvassing,
  LOCATION_TYPES.delivery,
  LOCATION_TYPES.venue,
] as const

export const REGISTRATION_LOCATION_TYPES = [
  LOCATION_TYPES.canvassing,
  LOCATION_TYPES.delivery,
] as const

export const PETITION_LOCATION_TYPES = [LOCATION_TYPES.canvassing] as const
