import {
  Button,
  ButtonBlock,
  ContentBlock,
  FieldBlock,
  Font,
  List,
  Modal,
  MultiSelectField,
  Section,
  TextBlock,
} from '@politechdev/blocks-design-system'
import {
  useDeliveryState,
  Delivery,
} from 'deliveries/DeliverySingle/DeliveryContext'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getEligibleFormIds } from 'deliveries/DeliverySingle/utils'
import { FormWithShiftInfo, IneligibleFormsData } from '../types'
import { buildCountyOptions, formIneligibleOnlyByCounty } from '../utils'

const removeSelectedOptions = (
  countyOptions: Array<{ value: string; label: string }>,
  currentDelivery: Delivery
) =>
  countyOptions.filter(
    option => !currentDelivery.form_filter_counties.includes(option.value)
  )

const AddCountyModal = ({
  isOpen,
  setIsOpen,
  showLoading,
  handleContinue,
  ineligibleFormsData,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  showLoading: boolean
  handleContinue: (
    newCounties: string[],
    newlyEligibleForms: FormWithShiftInfo[]
  ) => Promise<void>
  ineligibleFormsData: IneligibleFormsData
}) => {
  const { t } = useTranslation()

  const { currentDelivery, pendingUpdates, packets } = useDeliveryState()

  const [selectedCounties, setSelectedCounties] = useState<string[]>([])

  const eligibleFormIds = getEligibleFormIds({
    delivery: currentDelivery,
    pendingUpdates,
    packets,
    searchTerm: '',
    options: {
      county: false,
    },
  }) as number[]

  const countyOptions = buildCountyOptions(eligibleFormIds, packets)
  const cleanCountyOptions = removeSelectedOptions(
    countyOptions,
    currentDelivery
  )

  const assemblableForms = Object.values(ineligibleFormsData).filter(
    form =>
      selectedCounties.includes(form.county!) &&
      formIneligibleOnlyByCounty(form, currentDelivery)
  )

  const onContinue = async () => {
    await handleContinue(selectedCounties, assemblableForms)
    setSelectedCounties([])
    setIsOpen(false)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      title={t('Add more counties to delivery')}
      isOpen={isOpen}
      showLoading={showLoading}
    >
      <Modal.Body>
        <Section>
          <TextBlock>
            <Font.Copy Element="p">
              {t(
                'Adding more counties may bring in new packets that were previously not eligible for delivery in the selection step.'
              )}
            </Font.Copy>
            <Font.Copy Element="p">
              {t(
                'Counties cannot be removed from this delivery without reverting back to the selection step.'
              )}
            </Font.Copy>
          </TextBlock>
          <FieldBlock>
            <MultiSelectField
              label={t('Add counties')}
              values={selectedCounties}
              onSelect={value => setSelectedCounties(value)}
              options={cleanCountyOptions}
              icon={undefined}
            />
          </FieldBlock>
        </Section>
        {!!assemblableForms.length && (
          <Section>
            <TextBlock>
              <Font.Copy Element="p">
                {t(
                  'The following ineligible forms will become eligible for delivery with this change'
                )}
              </Font.Copy>
            </TextBlock>
            <List
              loading={false}
              itemData={assemblableForms as Record<string, unknown>[]}
              emptyMessage={t('No forms ready for assembly')}
              render={item => {
                const form = item as FormWithShiftInfo
                return (
                  <List.Item>
                    <ContentBlock>
                      <Font.Copy>{`Scan ${form.scan_number} - ${form.display_name}`}</Font.Copy>
                      <Font.Copy variant="hint">{form.shift.name}</Font.Copy>
                    </ContentBlock>
                    <ContentBlock>
                      <Font.Copy>{form.county}</Font.Copy>
                    </ContentBlock>
                  </List.Item>
                )
              }}
            />
          </Section>
        )}
      </Modal.Body>
      <Modal.Actions>
        <ButtonBlock justify="right">
          <Button.Secondary onClick={onCancel}>{t('Cancel')}</Button.Secondary>
          <Button.Accent onClick={onContinue}>{t('Submit')}</Button.Accent>
        </ButtonBlock>
      </Modal.Actions>
    </Modal>
  )
}

export default AddCountyModal
